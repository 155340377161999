import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
} from 'react'

export const SideNavigationContext = createContext()

export const useSideNavigationContext = () => {
  return useContext(SideNavigationContext)
}

const useInViewSectionsOvservers = (onEnterView, options = {}) => {
  const sectionRefs = useRef([])

  useEffect(() => {
    const handleScroll = () => {
      let maxVisibleArea = 0
      let maxVisibleIndex = -1

      sectionRefs.current.forEach((sectionRef, index) => {
        const boundingBox = sectionRef.current?.getBoundingClientRect()
        if (boundingBox) {
          const visibleArea =
            Math.min(window.innerHeight, boundingBox.bottom) -
            Math.max(0, boundingBox.top)

          if (visibleArea > maxVisibleArea) {
            maxVisibleArea = visibleArea
            maxVisibleIndex = index
          }
        }
      })

      if (maxVisibleIndex !== -1) {
        onEnterView(maxVisibleIndex)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [onEnterView, options])

  // Create a ref for each section
  const createSectionRef = (index) => {
    sectionRefs.current[index] = useRef(null)
    return sectionRefs.current[index]
  }

  return { createSectionRef }
}

const SideNavigationContextProvider = ({ children }) => {
  const [highlightedNavItem, setHighlightedNavItem] = useState(0)

  const { createSectionRef } = useInViewSectionsOvservers(setHighlightedNavItem)

  return (
    <SideNavigationContext.Provider
      value={{ highlightedNavItem, setHighlightedNavItem, createSectionRef }}
    >
      {children}
    </SideNavigationContext.Provider>
  )
}

export default SideNavigationContextProvider
